<template>
    <div class="border-radius-3 bg-white ">
        <div class="pL-6 pR-24 mT-none mB-none md-size-100 field-search-container ac-map-box acn-map" style="width: 400px !important;">
            <v-icon color="black" class="pt-3 pr-2">mdi-magnify</v-icon>
            <input type="text"
                    v-model="accountMapSearchValue"
                    :placeholder="$stringConstants('accountMapSearchPlaceholder')"
                    class="input-search fm-light fc-grey fs-16 ac-map-input"
                    id="searchFields"
                    autocomplete="off"
                    v-on:keyup.enter="searchPlaces" />                   
        </div>
    </div>
</template>
<script>
    import accountComputedMixin from '@/components/account/account-computed';
    import { notify } from '@/utils/helpers';
    import { isValidLatitude, isValidLongitude } from '@/utils/helpers/gmaps-helpers';

    export default {
        mixins: [accountComputedMixin],
        methods: {
            isStringNumeric(stringValue) {
                if (typeof stringValue != "string") return false
                return !isNaN(stringValue) && !isNaN(parseFloat(stringValue))
            },
            intiatePlacesSearch(latitude, longitude) {
                this.$store.dispatch('navigateToAccountCoordinates', [latitude, longitude])
            },
            requestPlacesSearchAPI() {
                this.$store.dispatch('requestGooglePlacesSearchAccount')
            },
            searchPlaces() {
                if (this.accountMapSearchValue != '' && this.accountMapSearchValue != null) {
                    if (this.accountMapSearchValue && this.accountMapSearchValue.includes(',')) {
                        const searchInputList = this.accountMapSearchValue.split(',')
                        const searchInputHasCoordinates = searchInputList.length == 2 &&
                            this.isStringNumeric(searchInputList[0]) &&
                            this.isStringNumeric(searchInputList[1])

                        if (searchInputHasCoordinates) {
                            if (isValidLatitude(searchInputList[0]) && isValidLongitude(searchInputList[1])) {
                                this.intiatePlacesSearch(searchInputList[0], searchInputList[1])
                            } else {
                                notify(this.$stringConstants('accountInvalidCoordinates'), 'fail')
                            }
                        } else {
                            this.requestPlacesSearchAPI()
                        }

                    } else {
                        this.requestPlacesSearchAPI()
                    }
                }
            },
        }
    }
</script>

<style scoped>
.ac-map-box{
    top:26px !important; 
    width:340px !important;
}
.ac-map-input{
    width: 100%;
    transform: translate(31px, -27px); 
    font-size:14px!important
}
.acn-map input[placeholder] {
    transform: translateY(-24px) translateX(32px) !important;
}
</style>
