






















































































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';

export default {
    name: "TeamJobsBilling",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    methods: {
        onBillingValueChange() {
            this.enableTeamJobDiscard();
        }
    }
}
