import store from "../store";
import $ from 'jquery';
import {
    successColor,
    brandColor, defaultFadeInTimeout, logCssColors, logCssErrorColors, avatarColorPresets, avatarColors, paidInvoiceColor, unpaidInvoiceColor
} from './uiconstants';
import moment from 'moment';
import Vue from 'vue';
import { InvoiceStatusType } from "@/enum/invoiceStatusType";
import { months } from "./constants";

export const getCookie = (name: string): string => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length >= 2) return parts.pop().split(";").shift();
}

export const openOverlay = (id: string) => {
    $("#overlay").fadeIn(defaultFadeInTimeout);
    $(`#${id}`).fadeIn(defaultFadeInTimeout);
    $('body').css({
        height: "100%",
        overflow: "hidden"
    });
}

export const openOverlayPermanent = (id: string) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    $("#overlayPermanent").fadeIn(defaultFadeInTimeout);
    $(`#${id}`).fadeIn(defaultFadeInTimeout);
    $('body').css({
        height: "100%",
        overflow: "hidden"
    });
}

export const closeOverlay = (elementId: string) => {
    if ($('#' + elementId).length > 0) {
        $("#overlay").fadeOut(defaultFadeInTimeout);
        $(`#${elementId}`).fadeOut(defaultFadeInTimeout);
        $('body').css({
            height: "auto",
            overflow: "unset"
        });
    }
}

export const closeOverlayPermanent = (elementId: string) => {
    if ($('#' + elementId).length > 0) {
        $("#overlayPermanent").fadeOut(defaultFadeInTimeout);
        $(`#${elementId}`).fadeOut(defaultFadeInTimeout);
        $('body').css({
            height: "auto",
            overflow: "unset"
        });
    }
}

export const checkFieldsValidity = (fields: Array<any>) => {
    let allFieldsValid = true;
    fields.map((field: any) => {
        if (!(field != null && field !== '')) {
            allFieldsValid = false;
        }
    });
    if (allFieldsValid)
        return true;
    else
        return false;
}

export const notify = (msg: any, result?: string, callbackObject?: any) => {
    let messageToDisplay;
    if (typeof msg === "string") {
        messageToDisplay = Vue?.prototype?.$stringConstants(msg) || msg;   
    } else if (typeof msg === "object") {
        messageToDisplay = msg.message
    }
    store.commit('setNotification', {
        title: msg?.title,
        message: messageToDisplay,
        callback: callbackObject?.callback || '',
        actionText: callbackObject?.text || "Okay",
        type: result
    })
}

export const closeNotify = (timeout = 0) => {
    setTimeout(() => {
        store.commit('cleanUpNotification')
    }, timeout)
}

export const notifySnackbar = (msg: string, callbackObject?: any) => {
    store.commit('setNotification', {
        message: msg,
        callback: callbackObject?.callback,
        actionText: callbackObject?.text
    });
}

export const notifyAutoClose = (msg: string, timeSpan: number) => {
    const notiTitle = document.getElementsByClassName("model-heading-auto-close")[0];

    $(".modal").css({
        "background-color": "rgba(0,0,0,0.4)",
    });
    $(".modal-content").css({
        "width": "550px"
    });

    $("#notiAutoCloseModal").fadeIn(500);

    notiTitle.innerHTML = "";
    notiTitle.innerHTML = "Information";

    $('.modal-header').css({
        "background-color": brandColor
    });

    const notiMessage = document.getElementsByClassName("message-auto-close")[0];
    notiMessage.innerHTML = "";
    notiMessage.innerHTML = msg;

    setTimeout(() => {
        $("#notiAutoCloseModal").fadeOut(500);
    }, timeSpan);

}

export const notifyDev = (msg: string, result: string) => {
    const notiTitle = document.getElementsByClassName("model-heading")[0];

    $(".modal").css({
        "background-color": "rgba(0,0,0,0.4)",
    });
    $(".modal-content").css({
        "width": "850px"
    });
    $("#notiModal").fadeIn(500);
    notiTitle.innerHTML = "";
    notiTitle.innerHTML = "Information";
    if (result === "success") {
        $('.modal-header').css({
            "background-color": successColor
        });
    } else if (result === "fail") {
        notiTitle.innerHTML = "";
        notiTitle.innerHTML = "Error";
        $('.modal-header').css({
            "background-color": '#000000'
        });
        $('.noti-title').text("Error");
    } else {
        $('.modal-header').css({
            "background-color": brandColor
        });
    }
    const notiMessage = document.getElementsByClassName("message")[0];
    notiMessage.innerHTML = "";
    notiMessage.innerHTML = msg;
}

export const getPolygonCoords = (polygonPath) => {
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
    const polygonCoords = [];
    let initialCoords;
    let i = 0;
    for (const coord of polygonPath) {
        const lat = coord.latitude * Math.PI / 180;
        const lng = coord.longitude * Math.PI / 180;
        if (i == 0) {
            initialCoords = { "lat": coord.latitude, "lng": coord.longitude };
            i = 1;
        }
        polygonCoords.push({ "lat": coord.latitude, "lng": coord.longitude });
        x += Math.cos(lat) * Math.cos(lng);
        y += Math.cos(lat) * Math.sin(lng);
        z += Math.sin(lat);
    }
    polygonCoords.push(initialCoords);
    const total = polygonPath.length;

    x = x / total;
    y = y / total;
    z = z / total;

    return [x, y, z, polygonCoords];
}

export const createMarkerIcon = (fillColor: string, scale: any) => {
    const icon = {
        path: "M-30,0a30,30 0 2,0 60,0a30,30 0 2,0 -60,0",
        fillColor: fillColor,
        strokeColor: "#fff",
        fillOpacity: 1,
        anchor: new window.google.maps.Point(0, 0),
        strokeWeight: 1,
        scale: scale
    };
    return icon;
}

export const getUTCDateFormat = (date) => {
    return moment(date).utc().format();
}

export const getHoursAndMins = (timeDurationInSeconds) => {
    const timeDurationInMinutes = Math.floor(timeDurationInSeconds / 60);
    let hours = Math.floor(timeDurationInMinutes / 60);
    let minutes = timeDurationInMinutes % 60;

    const remainingSeconds = timeDurationInSeconds % 60;

    if (remainingSeconds >= 30)
        minutes += 1;

    if (minutes === 60) {
        minutes = 0;
        hours += 1;
    }

    const totalMinutes = hours * 60 + minutes;
    return { hours, minutes, totalMinutes };
};

export const getMinutesFromSeconds = (timeDurationInSeconds) => {
    return Math.floor(timeDurationInSeconds / 60);
}

export const getHoursAndMinTimeSheet = (time) => {
    const hour = Math.floor(time / 60);
    const min = time % 60;
    return {
        hours: hour,
        minute: min
    }
}

export const getHoursAndMinsText = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    if(hours && minutes === 0) {
        return `${hours} hr${hours > 1 ? 's' : ''}`
    } else if (hours === 0 && minutes) {
        return `${minutes} mins`
    } else if (hours && minutes) {
        return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} mins`
    } else {
        return 'N/A'
    }
   
}

export const getDateFormatted = (date: string) => {
    return date ? moment.utc(date).local().format("DD MMM YYYY") : "-";
}

export const getDateWithoutTime = (date) => {
    const dateIN = moment.utc(date).local().format('YYYY-MM-DD');
    return dateIN
}

export const validateRegexAlphaNumeric = event => {
    if (!(/[a-zA-Z0-9\s]/.test(event.key))) {
        event.preventDefault();
    }
}

export const validateRegexAlpha = event => {
    if (!(/[a-zA-Z\s]/.test(event.key))) {
        event.preventDefault();
    }
}

export const validateRegexAlphaCaps = event => {
    if (!(/[A-Z\s]/.test(event.key))) {
        event.preventDefault();
    }
}

export const validateRegexNumeric = e => {
    if (!(/[^-+]/.test(e.key))) {
        e.preventDefault();
    }
}

export const validateRegexPhoneNumber = event => {
    const regex = /^[+0-9\s]/;
    if (!(regex.test(event.key)) && event.keyCode !== 8) {
        event.preventDefault();
        return false
    }
    return true
}

export const getDateDifferenceInDays = (endDate) => {
    const expiryDate = moment.utc(endDate).local().format();
    const currentDate = moment();
    return (-Math.round((moment.duration(currentDate.diff(expiryDate)).asDays()))).toString();
}

export const getDateDifferenceInMinutes = (endDate) => {
    const expiryDate = moment(endDate);
    const currentDate = moment();
    const dateDiff = (-Math.round((moment.duration(currentDate.diff(expiryDate)).asMinutes()))).toString()
    return dateDiff
}

export const beautifyTimespan = (timeInSeconds: number) => {
    if (timeInSeconds && timeInSeconds > 0) {
        const minsTemp = timeInSeconds / 60;
        let hours = Math.floor(minsTemp / 60);
        const mins = minsTemp % 60;

        if (hours != 0 && mins != 0) {
            if (mins >= 59) {
                hours += 1;
                return hours + " hr ";
            } else {
                return hours + " hr " + mins.toFixed(0) + " mins"
            }
        }
        else if (hours == 0 && mins != 0) {
            return mins.toFixed(0) + " mins"
        }
        else if (hours != 0 && mins == 0) {
            return hours + " hr"
        }
    } else {
        return '-'
    }
}

export const getCapitalised = (unformattedString: string) => {
    return unformattedString ? (unformattedString.charAt(0).toUpperCase() + unformattedString.slice(1).toLowerCase()) : '';
}

export const getAreaUnit = () => {
    let convertedAreaUnit = null;
    switch (localStorage.getItem('defaultAreaMeasurementUnit')) {
        case '0':
            // Hectare
            convertedAreaUnit = 'ha';
            break;
        case '1':
            // Acre
            convertedAreaUnit = 'acre';
            break;
        default:
            convertedAreaUnit = 'Sq m';
            break;
    }
    return convertedAreaUnit;
}

export const getConvertedArea = (areaInSquareMeter) => {
    let convertedArea;
    if (!areaInSquareMeter) return null;
    
    switch(localStorage.getItem('defaultAreaMeasurementUnit')) {
        case '0':
            //Hectare
            convertedArea = (areaInSquareMeter / 10000).toFixed(2);
            break;
        case '1':
            convertedArea = (areaInSquareMeter / 4047).toFixed(2);
            break;
        default:
            convertedArea = (areaInSquareMeter).toFixed(2);
            break
    }

    return convertedArea
}

export const getConvertedAreaUnit = (areaInSquareMeter: any) => {
    return (getConvertedArea(areaInSquareMeter) || 0) + ' ' + getAreaUnit();
}

export const convertToSquareMeters = (recievedArea: any) => {
let reverseConvertedArea = null;
    switch (localStorage.getItem('defaultAreaMeasurementUnit')) {
        case '0':
            // Hectare to sqm
            reverseConvertedArea = (recievedArea * 10000).toFixed(2);

            break;
        case '1':
            // Acre to sqm
            reverseConvertedArea = (recievedArea * 4047).toFixed(2);
            break;
    }
    return reverseConvertedArea
}

export const getJobStatus = (status) => {
    switch (status) {
        case 0: return 'New';
        case 1: return 'Scheduled';
        case 2: return 'In-progress';
        case 3: return 'Paused';
        case 4: return 'Awaiting completion';
        case 5: return 'Part completed';
        case 6: return 'Completed';
        case 7: return 'Cancelled';
    }
}

export const parseFloatLocal = (str, val) => {
    str = str.toString();
    str = str.slice(0, (str.indexOf(".")) + val + 1);
    return Number(str);
}

export const parseFloatDecimal = (val) => {
    return (Math.round(val * 100) / 100).toFixed(2);
}

export const getDateTimeFormat =  (date) => {
    return moment.utc(date).local().format("DD MMM YYYY")
}

export const getDateTimeFormattedLocal = (dateTime) => {
    return dateTime ? moment.utc(dateTime).local().format('DD MMM YYYY, hh:mm A') : ''
}

export const deleteObjectFromList = (haystack: Array<any>, needle: any, property: string) => {

    const needleIndex = haystack.findIndex(item => item[property] == needle);
    if (needleIndex != -1) haystack.splice(needleIndex, 1);

    return haystack;
}
export const deleteObjectFromMachineList = <T>(haystack: Array<T>, needle: any, property: string) => {
    const needleIndex = haystack.findIndex(item => item[property] == needle);
    haystack.splice(needleIndex, 1);

    return haystack;
}

export const generateRandomHexColor = () => {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    if (randomColor.length != 7) {
        return generateRandomHexColor();
    } else {
        return randomColor;
    }
}

export const isShortCodeLengthValid = (shortCode) => {
    return (shortCode && shortCode.length >= 2);
}


export const responseHasListData = (result) => {
    if (result && result.data && result.data.value) {
        return true;
    }
    return false;
}

export const getResponseData = (result) => {
    if (result && result.data && result.data.value) {
        return result.data.value;
    }
    return [];
}

export const listContainsItem = (list, listItemIdentifiers, itemIdToCheckFor) => {
    function getItemsObject(listItem, listItemIdentifiers) {
        let items = 'listItem';
        listItemIdentifiers.forEach((item, index) => {
            items += `[listItemIdentifiers[${index}]]`;
        })
        return items;
    }

    if (list && list.length) {
        return list.find(listItem => eval(`${`${getItemsObject(listItem, listItemIdentifiers)}`}`) == itemIdToCheckFor);
    } else {
        return false;
    }
}

export const reduceText = (text: string, maxTextLength: number) => {
    if (text.length <= maxTextLength) return text;
    return text.slice(0, maxTextLength) + '..';
}

export const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}

export const getInitials = (data: string) => {
    if (data != null) {
        let chr = null;
        let d = data.replace(/[^a-zA-Z0-9]/g, " ");
        d = d.toUpperCase().replace(/\s\s+/g, " ");
        chr = d.trim().split(" ");
        if (chr.length >= 2) {
            return chr[0][0] + chr[1][0];
        } else {
            return d[0] + d[1];
        }
    }
    return "";
}

export const getCommaSeparatedQuery = (queries, queryIdentifier) => {
    if (queries) {
        return queries.trim().split(',').map(id => `${queryIdentifier}=${id}`).join('&')
    } else {
        notify('Invalid query parameters passed!', 'fail');
    }
}

export const getShortName = (str: string, size: number) => {
    if (str) {
        if(str.length <= size) return str;
        return str.slice(0, size) + '..';
    }
    return '';
}

export const devLogger = () => {
    const isProd = process.env.NODE_ENV == 'production'; 

    return {
        log: function log(str: string | any) {
            if(isProd) return;
            console.log('%c%s', logCssColors, str);
        }, 
        logNewline: function logNewline(str: string | any) {
            if(isProd) return;
            console.log('%c%s\n', logCssColors, str);
        },
        logAllEnv: function logAllEnv(str: string | any) {
            console.log('%c%s', logCssColors, str);
        },
        logError: function logError(str: string | any) {
            if(isProd) return;
            console.log('%c%s', logCssErrorColors, str);
        },
        logErrorAllEnv: function logErrorAllEnv(str: string | any) {
            console.log('%c%s', logCssErrorColors, str);
        },
        logErrorNewline: function logErrorNewline(str: string | any) {
            if(isProd) return;
            console.log('%c%s\n', logCssErrorColors, str);
        },
    }
}

export const getColorPreset = (initials: string) => {
    const initialLowered = initials[0]?.toLowerCase();
    const presetColor = avatarColorPresets.find(p => p.group.includes(initialLowered));
    const selectedColor = presetColor 
                          ? presetColor.color 
                          : avatarColors[Math.floor(Math.random() * avatarColors.length)];

    return selectedColor;
}

export const getZeroPadding = (num, places) => {
    return String(num).padStart(places, '0');
}

export const getListLengthAbsolute = () => {

    function getListLength(list) {
        if(Array.isArray(list) && list.length > 0) {
            return list.length
        }
    }

    function getListLengthWithOwner(list) {
        return (getListLength(list) - 2) || 0
    }

    function getListLengthWithoutOwner(list) {
        return (getListLength(list) - 1) || 0
    }

    function getListLengthActual(list) {
        return (getListLength(list)) || 0
    }

    return {
        getListLengthWithOwner,
        getListLengthWithoutOwner,
        getListLengthActual,
    }
}

export const getSpacedText = (text: string) => {
    const result = text.replace( /([A-Z])/g, " $1" );
    const upperCased = result.charAt(0).toUpperCase() + result.slice(1);
    return upperCased;
}

export const sortObjectsListByIdentifier = (list, objectIdentifier) => {
    return list.sort((objectHead:any, objectTail:any) => 
        objectHead[objectIdentifier] < objectTail[objectIdentifier] ? -1 
        : (objectHead[objectIdentifier] > objectTail[objectIdentifier] ? 1 : 0)
    )
}

export const preventKeyControlsForDropdown = (keyCode) => {
    const keysCode = [13, 9, 37, 38, 39, 40]
    if(keysCode.includes(keyCode)){
        return false;
    }
    else {
        return true;
    }
}
export const getAddressAsSingleString = (address) => {
    const line1 = address.addressLine1 ? address.addressLine1 : "";
    const line2 = " " + (address.addressLine2 ? address.addressLine2 : "");
    const city = " " + (address.city ? address.city : "");
    const state = " " + (address.state ? address.state : "");
    const country = " " + (address.country ? address.country : "");
    const postalCode = " " + (address.postalCode ? address.postalCode : "");
    const addressArray = [line1, line2, city, state, country, postalCode];
    return addressArray.filter(a => a && a != ' ').join();
}

export const toCamelCase = (pascalCaseObject) => {
    let newObject, origKey, newKey, value
    if (pascalCaseObject instanceof Array) {
      return pascalCaseObject.map(function(value) {
          if (typeof value === "object") {
            value = toCamelCase(value)
          }
          return value
      })
    } else {
      newObject = {}
      for (origKey in pascalCaseObject) {
        if (Object.prototype.hasOwnProperty.call(pascalCaseObject, origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = pascalCaseObject[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = toCamelCase(value)
          }
          newObject[newKey] = value
        }
      }
    }
    return newObject
}

export const generateMonthlyDataByYear = (monthlyData) => {

    function getBarColor(invoiceStatus) {
        return InvoiceStatusType.Paid == invoiceStatus ? paidInvoiceColor : unpaidInvoiceColor
    }

    function getCurrencyCode() {
        return monthlyData?.[0]?.currencyCode?.toUpperCase()
    }

    const chartDataArr = [
        ['Month', `Amount (${getCurrencyCode()})`, { role: 'style '}]
    ]

    months.map(m => {
        chartDataArr.push([
            m.title,
            (monthlyData.filter(md => md.month == m.value)?.[0]?.amount || 0),
            (getBarColor(monthlyData.filter(md => md.month == m.value)?.[0]?.status) || unpaidInvoiceColor)
        ])
    })

    return chartDataArr
}

export const clearDateTimePicker = (target) => {
    const datePickerResetEvent = new MouseEvent('click', {
        bubbles: false,
        cancelable: false
    });
    const datePickerResetButton = target.querySelector('.vdpr-datepicker__button-reset');
    const datePickerSwitchButton = target.querySelector('.vdpr-datepicker__switch');

    if (datePickerResetButton)
        datePickerResetButton.dispatchEvent(datePickerResetEvent);

    if (datePickerSwitchButton) {
        const timeoutID = setTimeout(() => {
            datePickerSwitchButton.dispatchEvent(datePickerResetEvent);
            clearTimeout(timeoutID);
        });
    }
}

export const initializeDateTimePlaceholders = () => {
    const dInputStartDate = document.getElementsByClassName("vdpr-datepicker__calendar-input-date-elem")[0] as HTMLInputElement;
    if (dInputStartDate)
        dInputStartDate.placeholder = "DD/MM/YYYY";

    const dInputEndDate = document.getElementsByClassName("vdpr-datepicker__calendar-input-date-elem")[1] as HTMLInputElement;
    if (dInputEndDate)
        dInputEndDate.placeholder = "DD/MM/YYYY";

    const dInputStartTime = document.getElementsByClassName("vdpr-datepicker__calendar-input-time-elem")[0] as HTMLInputElement;
    if (dInputStartTime)
        dInputStartTime.placeholder = "HH:MM";

    const dInputEndTime = document.getElementsByClassName("vdpr-datepicker__calendar-input-time-elem")[1] as HTMLInputElement;
    if (dInputEndTime)
        dInputEndTime.placeholder = "HH:MM";
}

export const sleep = (milliseconds: number) => {
    return new Promise((resolve) => {
        const timeoutID = setTimeout(() => resolve(timeoutID), milliseconds);
    });
}

const replaceAllDoubleNewLinesWithSingle = (text) => {
    return text.replace(/\n{2,}/g, '\n');
  };

export const htmlToText = (htmlString) => {
    if (!htmlString || typeof htmlString !== 'string') {
      console.error('Invalid input: not a string');
      return '';
    }
  
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
  
    let textOutput = '';
  
    const extractText = (element) => {
      return Array.from(element.childNodes)
        .map((node: any) => {
          if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent;
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            if (node.nodeName === 'BR') {
              return ' '; 
            } else if (node.nodeName === 'P') {
              return extractText(node) + '\n'; 
            } else if (node.nodeName === 'A') {
              return node.textContent;
            }
            return extractText(node); 
          }
          return '';
        })
        .join('')
        .trim();
    };
    
    textOutput = replaceAllDoubleNewLinesWithSingle(extractText(tempDiv));
    return textOutput.trim();
  };
  
  export const textToHtml = (plainText) => {
  if (!plainText || typeof plainText !== 'string') {
    return '';
  }

  const urlRegex = /(\b(https?|ftp):\/\/[^\s/$.?#].[^\s]*|\bwww\.[^\s/$.?#].[^\s]*|\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}\b|\b(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}(?:\/[^\s]*)?)/gi;

  let htmlText = plainText.replace(urlRegex, (url) => {
    const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
    return `<a href="${formattedUrl}" rel="noopener noreferrer" target="_blank">${url}</a>`;
  });

  htmlText = htmlText.replace(/\r?\n/g, '[[NEWLINE]]');

  const paragraphs = htmlText.split('[[NEWLINE]]')
    .map(paragraph => paragraph.trim())
    .filter(paragraph => paragraph.length > 0);

  htmlText = paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('');

  return htmlText;
};

  
  
  
  