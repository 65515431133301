























































































































































































































































































































































































































































































































































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import OperatorAvatar from '@/components/shared/avatars/avatar.vue';
import { listContainsItem, beautifyTimespan, getShortName, getHoursAndMins, getZeroPadding, getMinutesFromSeconds } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import { TeamJobOperatorChecklistResourceType } from '@/enum/teamJobOperatorChecklistResourceType';
import { getChecklistItemFromResource, getPageParam } from '@/utils/helpers/jobs-helpers';
import { JobOperatorWorkStatusType, JobOperatorWorkStatusTypeLabel } from '@/enum/jobOperatorWorkStatusType';
import { JobOperatorTravelStatusType, JobOperatorTravelStatusTypeLabel } from '@/enum/jobOperatorTravelStatusType';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { teamJobOperatorIdleColor } from '@/utils/uiconstants';
import { ResourceType } from '@/enum/resourceType';
import ConstantValues from '@/plugins/constantValues';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { acceptableBillingUnitsForBillableQuantityUpdate } from '@/utils/constants';

export default {
    name: "TeamJobsOperators",
    props: {
        minimal: Boolean
    },
    data() {
        return {
            TeamJobOperatorChecklistResourceType,
            teamJobOperatorIdleColor,
            ResourceType,
            searchTimer: null,
            searchTimerVehicles: null,
            searchTimerImplements: null,
            activeOperatorIndex: null
        }
    },
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
        OperatorAvatar,
        LoadMoreDropdown,
        Button
    },
    computed: {
        isTeamJobOperatorsSelectable: {
            get() {
                return this.teamJobViewPreference.operators.isEditable 
                       && this.teamJobOperation
                       && (this.teamJobDetails 
                            && (this.teamJobDetails.status == TeamJobStatusType.AwaitingCompletion 
                                || (this.teamJobDetails.status == TeamJobStatusType.Completed && !this.teamJobDetails.isApprovedForInvoicing) 
                                || this.teamJobDetails.status == TeamJobStatusType.PartCompleted))
                       && this.teamJobOperators.length > 0
                       && this.teamJobOperators.some(op => op.operatorDetails.timeline && op.operatorDetails.timeline.length > 0)
                       && (this.teamJobOperation.billingQuantities.length > 0) 
                       && acceptableBillingUnitsForBillableQuantityUpdate.includes(this.teamJobOperation.billingUnit);
            }
        },
        isAnyOperatorSelected: {
            get() {
                return this.teamJobSelectedOperators.length > 0;
            }
        }
    },
    watch: {
        teamJobOperators: {
            handler: function (val) {
                if (val.length != 0) {
                    const x = val.some(el => (el.isTeamLead));
                    if (!x) {
                        this.teamJobOperators[0].isTeamLead = true;
                    }
                }
            },
            deep: true
        },
        isAllOperatorsSelected: {
            handler: function (val) {
                if (val) 
                    this.teamJobSelectedOperators = this.teamJobOperators.map(op => op.operatorDetails.id)
                else 
                    this.teamJobSelectedOperators = []
            }
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
        }),
        enableDiscardButtonFromOperators() {
            if (this.teamJobViewPreference.operators.isDetailed) {
                this.enableTeamJobDiscard();
            }
        },
        disableCheckBox(opr) {
            const indx = [];
            if (opr.isTeamLead) {
                for (let i = 0; i < this.teamJobOperators.length; i++) {
                    if (this.teamJobOperators[i].isTeamLead) {
                        indx.push(i);
                    }
                }
                if (indx.length == 1) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        removeOperatorFromOperatorsList(operatorIndex) {
            this.teamJobOperators = this.teamJobOperators.filter((opr, index) => index !== operatorIndex);
            this.enableDiscardButtonFromOperators();
        },
        updateSearchVehiclesText(e, operatorIndex) {
            const searchedText = e.target.value;
            if (searchedText.length == 0) {
               this.teamJobVehiclesSearchInputListForOperators[operatorIndex] = null;
               this.applySearchVehiclesForOperatorsTimeout(this.teamJobVehiclesSearchInputListForOperators[operatorIndex]);
            } else {
                this.teamJobVehiclesSearchInputListForOperators[operatorIndex] = searchedText;
                this.applySearchVehiclesForOperatorsTimeout(this.teamJobVehiclesSearchInputListForOperators[operatorIndex]);
         }
        },
        applySearchVehiclesForOperatorsTimeout(SearchText) {
            if (this.searchTimerVehicles) {
                clearTimeout(this.searchTimerVehicles);
                this.searchTimerVehicles = null;
            }
            this.searchTimerVehicles = setTimeout(() => {
                this.searchVehiclesForOperators(SearchText);
            }, ConstantValues.defaultSearchTimeout);
        },
        searchVehiclesForOperators(operatorVehicleSearchText) {
            this.clearFilterOptions();
            this.teamJobVehiclesSearchTextForOperators = operatorVehicleSearchText;
            this.$store.dispatch('setTeamJobVehiclesSearchFilters').then(res => {
                if (res != false) {
                    this.$store.dispatch('getTeamJobVehiclesList');
                    if (this.teamJobVehiclesSearchTextForOperators == '') {
                        this.clearFilterOptions();
                    }
                }
            });
        },
        updateSearchImplementsText(e, operatorIndex) {
            const searchedText = e.target.value;
            if (searchedText.length == 0) {
               this.teamJobImplementsSearchInputListForOperators[operatorIndex] = null;
               this.applySearchImplementsForOperatorsTimeout(this.teamJobImplementsSearchInputListForOperators[operatorIndex]);
            } else {
                this.teamJobImplementsSearchInputListForOperators[operatorIndex] = searchedText;
                this.applySearchImplementsForOperatorsTimeout(this.teamJobImplementsSearchInputListForOperators[operatorIndex]);
          }
        },
        applySearchImplementsForOperatorsTimeout(SearchText) {
            if (this.searchTimerImplements) {
                clearTimeout(this.searchTimerImplements);
                this.searchTimerImplements = null;
            }
            this.searchTimerImplements = setTimeout(() => {
                this.searchImplementsForOperators(SearchText);
            }, ConstantValues.defaultSearchTimeout);
        },
        searchImplementsForOperators(operatorImplementSearchText) {
            this.clearFilterOptions();
            this.teamJobImplementsSearchTextForOperators = operatorImplementSearchText;
            this.$store.dispatch('setTeamJobImplementsSearchFilters').then(res => {
                if (res != false) {
                    this.$store.dispatch('getTeamJobImplementsList');
                    if (this.teamJobImplementsSearchTextForOperators == '') {
                        this.clearFilterOptions();
                    }
                }
            });
        },
        refeatchOperatorList(){
            this.hasMoreData = true;
            if(this.teamJobTeamMembersForOperatorsSeachText != null && this.teamJobOperatorToAdd == null){
                this.teamJobTeamMembersForOperatorsSeachText = null;
                this.searchTeamMembersForTeamsJob();
            }
        },
        updateSearchOperatorText(e) {
            const searchedText = e.target.value;
            if (searchedText.length == 0) {
                this.teamJobTeamMembersForOperatorsSeachText = null;
                this.applySearchJobOperatorTimeout();
            } else {
                this.teamJobTeamMembersForOperatorsSeachText = searchedText;
                this.applySearchJobOperatorTimeout();
            }
        },
        applySearchJobOperatorTimeout() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.searchTeamMembersForTeamsJob();
            }, ConstantValues.defaultSearchTimeout);
        },
        searchTeamMembersForTeamsJob() {
            this.teamJobsTeamMembersListForOperators = [];
            this.clearFilterOptions();
            this.$store.dispatch('setTeamMembersForTeamJobSearchText').then(res => {
                 this.$store.dispatch('getTeamJobTeamMembers');
                    if (this.teamJobTeamMembersForOperatorsSeachText == '') {
                        this.clearFilterOptions();
                    }
            });
        },
        appendMoreOperator(){
            this.clearFilterOptions();
            this.$store.dispatch('setTeamMembersForTeamJobSearchText').then(() => {
                this.$store.dispatch('getTeamJobTeamMembers');
            })
        },
        async addOperatorToOperatorsList() {
            if (this.teamJobOperatorToAdd) {
                this.teamJobOperatorToAddLoader = true;
                const selectedOperatorId = this.teamJobOperatorToAdd.id

                const selectedOperator = await this.$store.dispatch('getOperatorDetails', selectedOperatorId)
                selectedOperator.defaultImplement = null;
                selectedOperator.defaultVehicle = null;
                const operatorObj = {
                    isTeamLead: false,
                    operatorDetails: selectedOperator
                }

                if (selectedOperator) {
                    if (this.teamJobOperators.length) {
                        if (!this.isOperatorPresentInOperatorsList(this.teamJobOperators, operatorObj.operatorDetails.id)) {
                            this.teamJobOperators.push(operatorObj);
                            this.teamJobShowOperatorSelectionDropdown = false;
                            this.teamJobShowJobAddOperatorExpandedView = true;

                        }
                    } else {
                        this.teamJobOperators.push(operatorObj)
                        this.teamJobShowOperatorSelectionDropdown = false;
                        this.teamJobShowJobAddOperatorExpandedView = true;
                    }
                }
                this.teamJobOperatorToAdd = null;
                this.teamJobOperatorToAddLoader = false;
            }

            //add checklists to operator from operation if detailed view
            if (getPageParam() != 'add') {
                if (this.teamJobOperation) {
                    this.teamJobOperators[this.teamJobOperators.length - 1].operatorDetails['checklists'] = [];

                    const newOperationChecklistItem = getChecklistItemFromResource(this.teamJobOperation, TeamJobOperatorChecklistResourceType.Operation);
                    if (newOperationChecklistItem) {
                        this.teamJobOperators[this.teamJobOperators.length - 1].operatorDetails['checklists'].push(newOperationChecklistItem);
                    }
                }
            }

            this.enableDiscardButtonFromOperators();
        },
        showOperatorDropdown() {
            this.teamJobShowJobAddOperatorExpandedView = false;
            this.teamJobShowOperatorSelectionDropdown = true;
            this.activeOperatorIndex = this.teamJobOperators.length;
            setTimeout(() => {
                const elementCompact = document.getElementById('operatorSelectionMenuCompact');
                const elementDetailed = document.getElementById('operatorSelectionMenuDetailed');
                if (elementCompact) elementCompact.click();
                if (elementDetailed) elementDetailed.click();
            }, 100);
        },
        isOperatorPresentInOperatorsList(operatorsList, operatorId) {
            return listContainsItem(operatorsList, ['operatorDetails', ConstantValues.defaultObjectIdentifier], operatorId);
        },
        updateOperatorChecklist(operator, resourceType: TeamJobOperatorChecklistResourceType) {
            switch (resourceType) {
                case TeamJobOperatorChecklistResourceType.Vehicle:
                    this.enableDiscardButtonFromOperators();
                    if (this.teamJobDetails && this.teamJobDetails.status == TeamJobStatusType.Completed) {
                        if (operator && operator.checklists) {
                            operator.checklists = [];
                        }
                    } else {
                        if (operator.defaultVehicle) {
                            if (operator.checklists) {
                                const vehicleChecklistId = operator.checklists.find(cl => cl.resourceType == TeamJobOperatorChecklistResourceType.Vehicle);
                                if (vehicleChecklistId) {
                                    operator.checklists = operator.checklists.filter(cl => cl.resourceType != TeamJobOperatorChecklistResourceType.Vehicle);
                                }
                            }
                            const newVehicleChecklists = getChecklistItemFromResource(operator.defaultVehicle, TeamJobOperatorChecklistResourceType.Vehicle);
                            if (newVehicleChecklists) {
                                if (Object.prototype.hasOwnProperty.call(operator, 'checklists')) {
                                    operator.checklists.push(newVehicleChecklists);
                                }
                            }
                        } else {
                            operator.checklists = operator.checklists.filter(cl => cl.resourceType != TeamJobOperatorChecklistResourceType.Vehicle);
                        }
                    }
                    break;
                case TeamJobOperatorChecklistResourceType.Implement:
                    this.enableDiscardButtonFromOperators();
                    if (this.teamJobDetails && this.teamJobDetails.status == TeamJobStatusType.Completed) {
                        if (operator && operator.checklists) {
                            operator.checklists = [];
                        }
                    } else {
                        if (operator.defaultImplement) {
                            if (operator.checklists) {
                                const implementChecklistId = operator.checklists.find(cl => cl.resourceType == TeamJobOperatorChecklistResourceType.Implement);
                                if (implementChecklistId) {
                                    operator.checklists = operator.checklists.filter(cl => cl.resourceType != TeamJobOperatorChecklistResourceType.Implement);
                                }
                            }
                            const newImplementChecklist = getChecklistItemFromResource(operator.defaultImplement, TeamJobOperatorChecklistResourceType.Implement);
                            if (newImplementChecklist) {
                                if (Object.prototype.hasOwnProperty.call(operator, 'checklists')) {
                                    operator.checklists.push(newImplementChecklist);
                                }
                            }
                        } else {
                            operator.checklists = operator.checklists.filter(cl => cl.resourceType != TeamJobOperatorChecklistResourceType.Implement);
                        }
                    }
                    break;
                default: break;
            }
        },
        hasOperatorCompletedChecklists(operator) {
            const { finishedChecklistItems, totalChecklistItems } = this.getChecklistItemCounts(operator);
            return (finishedChecklistItems == totalChecklistItems) && totalChecklistItems != 0
        },
        getChecklistItemCounts(operator) {
            let totalChecklistItems = 0;
            let finishedChecklistItems = 0;

            if (operator && operator.checklists) {
                operator.checklists.forEach((chl) => {
                    const { items } = chl;
                    if (items) {
                        items.forEach((ci) => {
                            totalChecklistItems++;
                            if (ci.completedOn) {
                                finishedChecklistItems++;
                            }
                        })
                    }
                })
            }

            return {
                finishedChecklistItems,
                totalChecklistItems
            }
        },
        getChecklistsCount(operator) {
            const { finishedChecklistItems, totalChecklistItems } = this.getChecklistItemCounts(operator);
            return `${finishedChecklistItems}/${totalChecklistItems}`
        },
        getHoursAndMins(timeInSeconds: number) {
            return getHoursAndMins(timeInSeconds);
        },
        getTimeInHoursAndMins(operatorTimeInSeconds: number) {
            const { hours, minutes } = getHoursAndMins(operatorTimeInSeconds);
            if (hours > 0) {
                return `${hours} hrs ${minutes} mins`;
            } else {
                return `${minutes} mins`;
            }
        },
        getTimeInHoursAndMinsFormatted(operatorTimeInSeconds: number) {
            const { hours, minutes } = getHoursAndMins(operatorTimeInSeconds);
            return `${getZeroPadding(hours, 2)}:${getZeroPadding(minutes, 2)}`;
        },
        checkOperatorHasWorked(operator) {
            return (operator && operator.operatorDetails.timeline &&
                (this.getTimelineTotalTimeInSeconds(operator.operatorDetails.timeline, 'workTimeInSeconds') ||
                    this.getTimelineTotalTimeInSeconds(operator.operatorDetails.timeline, 'travelTimeInSeconds'))
            )
        },
        getTimelineTotalTimeInSeconds(timeline, timeType) {
            let totalDuration = 0;
            if (timeline) {
                timeline.forEach(tl => {
                    totalDuration += tl[`${timeType}`]
                })
            }
            return totalDuration
        },
        getWorkTimeDuration(operator) {
            if (this.checkOperatorHasWorked(operator)) {
                const operatorTimeDuration = this.getTimelineTotalTimeInSeconds(operator.operatorDetails.timeline, 'workTimeInSeconds');
                return this.getTimeInHoursAndMins(operatorTimeDuration);
            }
            return 'NA'
        },
        getWorkStatus(operator) {
            let workStatus = 'Idle';
            if (operator.workStatus != null) {
                switch (operator.workStatus) {
                    case JobOperatorWorkStatusType.Started: 
                         workStatus = JobOperatorWorkStatusTypeLabel.get(JobOperatorWorkStatusType.Started); break;
                    case JobOperatorWorkStatusType.Paused: 
                         workStatus = JobOperatorWorkStatusTypeLabel.get(JobOperatorWorkStatusType.Paused); break;
                    case JobOperatorWorkStatusType.Completed: 
                         workStatus = JobOperatorWorkStatusTypeLabel.get(JobOperatorWorkStatusType.Completed); break;
                    default: break;
                }
            } else if (operator.travelStatus != null) {
                switch (operator.travelStatus) {
                    case JobOperatorTravelStatusType.Started: 
                         workStatus = JobOperatorTravelStatusTypeLabel.get(JobOperatorTravelStatusType.Started); break;
                    case JobOperatorTravelStatusType.Paused: 
                         workStatus = JobOperatorTravelStatusTypeLabel.get(JobOperatorTravelStatusType.Paused); break;
                    case JobOperatorTravelStatusType.Completed: 
                         workStatus = JobOperatorTravelStatusTypeLabel.get(JobOperatorTravelStatusType.Completed); break;
                    default: break;
                }
            }
            return workStatus;
        },
        getShortenedName(operatorName) {
            return getShortName(operatorName, 17);
        },
        appendMoreResources(resourceType: ResourceType, searchText: string) {
            switch(resourceType) {
                case ResourceType.Vehicle: {
                    this.clearFilterOptions();
                    this.teamJobVehiclesSearchTextForOperators = searchText;
                    this.$store.dispatch('setTeamJobVehiclesSearchFilters').then(res => {
                        if (res) {
                            this.$store.dispatch('getTeamJobVehiclesList');
                            if (this.teamJobVehiclesSearchTextForOperators == '') {
                                this.clearFilterOptions();
                            }
                        }
                    });
                    break;
                }
                case ResourceType.Implement: {
                    this.clearFilterOptions();
                    this.teamJobImplementsSearchTextForOperators = searchText;
                    this.$store.dispatch('setTeamJobImplementsSearchFilters').then(res => {
                        if (res) {
                            this.$store.dispatch('getTeamJobImplementsList');
                            if (this.teamJobImplementsSearchTextForOperators == '') {
                                this.clearFilterOptions();
                            }
                        }
                    });
                    break;
                }
                default: return;
            }
        },
        updateSelectedOperatorsTotalWorkHours() {
            this.teamJobSelectedOperatorsHours = 0;

            if (this.teamJobSelectedOperators) {
                this.teamJobSelectedOperators.forEach(operatorId => {
                    const operator = this.teamJobOperators.find(op => op.operatorDetails.id == operatorId);
                    this.teamJobSelectedOperatorsHours += this.getTimelineTotalTimeInSeconds(operator.operatorDetails.timeline, 'workTimeInSeconds');
                });
            }
        },
        toggleSelectedOperatorsTotalWorkHours() {
            this.$nextTick(() => {
                if (this.isAllOperatorsSelected)
                    this.updateSelectedOperatorsTotalWorkHours();
                else
                    this.teamJobSelectedOperatorsHours = 0;
            });
        },
        setWorkHoursAsBillableQuantity() {
           this.$store.dispatch('setWorkHoursAsBillableQuantity', { workTimeInSeconds: this.teamJobSelectedOperatorsHours });
           this.enableTeamJobDiscard();
        },
        getMinutesFromSeconds(seconds: number) {
            return getMinutesFromSeconds(seconds)
        },
    }
}
